import React from "react"
import PropTypes from "prop-types"

// Components
import { graphql, navigate } from "gatsby"
import BlogCard from '../components/BlogCard'

import Layout from '../components/Layout'
import SEO from "../components/seo"
import { Chip, makeStyles } from "@material-ui/core"
import CodeIcon from "@material-ui/icons/Code"

const useStyles = makeStyles((theme) => ({
  tagResults: {
    display: "flex",
    marginTop: "10px",
    marginBottom: "10px",
    flexGrow: 1,
    flexDirection: "row",
    flexWrap: "wrap",
  },
  whiteSpace : {
    width: 0,
    flexGrow: 1,
  },
}));

const Tags = ({ pageContext, data }) => {
  const { tag } = pageContext
  const { edges, totalCount } = data.allJobsJson
  const tagHeader = `${totalCount} job${
    totalCount === 1 ? "" : "s"
  } tagged with "${tag}"`

  const classes = useStyles()
  const pageTitle = `Knowing ${tag} lets you work abroad`

  return (
  	<Layout>
      <SEO title={pageTitle} description={pageTitle} />
	    <div>
        <div className={classes.tagResults} >
          <Chip variant="outlined" label={`${tagHeader}`} />
          <div className={classes.whiteSpace} />
          <Chip 
                icon={<CodeIcon />}
                label="Back to all categories"
                color="primary"
                variant="outlined"
                onClick={(event) => {navigate("/categories")}}
          />
                {/*
                  guid was slug before!
                */}
        </div>
        {edges.map((edge, key) => (
          <BlogCard
            key={key}
            post={{
              ...edge.node,
              tag_list_array: edge.node.categories,
            }}
          />
        ))}
	      {/*
	              This links to a page that does not yet exist.
	              You'll come back to it!
	            */}
	    </div>
    </Layout>
  )
}

Tags.propTypes = {
  pageContext: PropTypes.shape({
    tag: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    allJobsJson: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            title: PropTypes.string.isRequired,
          }),
        }).isRequired
      ),
    }),
  }),
}

export default Tags

export const pageQuery = graphql`
  query($tag: String) {
    allJobsJson(
      limit: 1000
      sort: { order: DESC, fields: [isoDate] }
      filter: { categories: { in: [$tag] } }
    ) {
      totalCount
      edges {
        node {
          guid
          title
          categories
          companyLogo
          author {name: a10_name}
          isoDate
          promoMainIsoDate
        }
      }
    }
  }
`